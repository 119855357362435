@charset "UTF-8";

// #region basic
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
    display: block;
}

html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

img {
    max-width: 100%;
    width: auto\9;
    height: auto;
    vertical-align: middle;
    border: 0;
    -ms-interpolation-mode: bicubic;
}

button,
input,
select,
textarea {
    margin: 0;
    font-size: 100%;
    vertical-align: middle;
}

button,
input {
    *overflow: visible;
    line-height: normal;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    padding: 0;
    border: 0;
}

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
    -webkit-appearance: button;
    cursor: pointer;
}

label,
select,
button,
input[type='button'],
input[type='reset'],
input[type='submit'],
input[type='radio'],
input[type='checkbox'] {
    cursor: pointer;
}

input[type='search'] {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-appearance: textfield;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

textarea {
    overflow: auto;
    vertical-align: top;
    resize: vertical;
    outline: 0;
}

input[type='text'],
input[type='email'],
input[type='url'],
input[type='password'],
textarea {
    padding: 5px;
    border: 1px solid #e9e9e9;
    width: 100%;
    border-radius: 2px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    outline: 0;
}

pre,
code {
    background: #f3f3f0;
    font-family: 'Helvetica Neue', 'Luxi Sans', 'DejaVu Sans', Tahoma,
        'Hiragino Sans GB', 'Microsoft Yahei', sans-serif;
    font-size: 0.92857em;
}

code {
    padding: 2px 4px;
    color: #b94a48;
}

pre {
    padding: 10px;
    overflow: auto;
}

pre code {
    padding: 3px;
    color: #444;
}

blockquote {
    margin: 1em 1.5em;
    padding-left: 1.5em;
    border-left: 4px solid #f2f2f2;
}

body {
    margin: 0;
    font-family: 'Helvetica Neue', 'Luxi Sans', 'DejaVu Sans', Tahoma,
        'Hiragino Sans GB', 'Microsoft Yahei', sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: #555;
    background-color: white;
}

a {
    color: #555;
    text-decoration: none;
}

a:focus {
    outline: thin dotted #333;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
    color: #000;
    text-decoration: underline;
}

a:hover,
a:active {
    color: #0c843f;
    outline: 0;
    text-decoration: none;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track-piece {
    background-color: #e0e0e0;
}

::-webkit-scrollbar-thumb {
    border: 0;
}

::-webkit-scrollbar-thumb:vertical:hover,
::-webkit-scrollbar-thumb:horizontal:hover {
    background-color: #aaa;
}

::-webkit-scrollbar-thumb:vertical {
    height: 5px;
    background-color: #bbb;
}

::-webkit-scrollbar-thumb:horizontal {
    width: 5px;
    background-color: #bbb;
}

::selection {
    color: #fff;
    background-color: #2b2b2b;
}

::-moz-selection {
    color: #fff;
    background-color: #2b2b2b;
}

.fl {
    float: left;
}

.fr {
    float: right;
}

.browsehappy {
    padding: 8px 0;
    background: #fbe3e4;
    color: #8a1f11;
    text-align: center;
}

.browsehappy a {
    color: #8a1f11;
    text-decoration: underline;
    font-weight: bold;
}

.btn {
    padding: 4px 6px;
    font-size: 12px;
    line-height: 1.5;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    border: 1px solid;
    border-color: #efefef #e5e5e5 #e5e5e5 #efefef;
    background-color: #f4f4f4;
    cursor: pointer;
}

.btn.large {
    padding: 11px 19px;
    font-size: 14px;
}

.btn.block {
    display: block;
    text-align: center;
    width: 100%;
}

.btn:hover {
    border-color: #e8e8e8 #dbdbdb #dbdbdb #e8e8e8;
    background-color: #ededed;
    text-decoration: none;
}

.btn.s1 {
    background-color: #888;
    color: #fff;
    border-color: #888;
}

.btn.s1:hover {
    border-color: #7d7d7d;
    background-color: #7d7d7d;
}

.btn.s2 {
    background-color: #ff9600;
    color: #fff;
    border-color: #ff9600;
}

.btn.s2:hover {
    border-color: #ff7e00;
    background-color: #ff7e00;
}

.btn.s3 {
    color: #fff;
    border-color: #10a64f;
    background-color: #10a64f;
}

.btn.s3:hover {
    border-color: #0c843f;
    background-color: #0c843f;
}

.error-page {
    min-height: 300px;
    margin-top: 100px;
}

.hide {
    display: none;
}

.clearfix {
    zoom: 1;
}

.clearfix:before,
.clearfix:after {
    content: ' ';
    display: table;
}

.clearfix:after {
    clear: both;
}

.alignleft {
    text-align: left;
}

.aligncenter {
    text-align: center;
}

.alignright {
    text-align: right;
}

// #endregion


/* wrapper */

body {
    padding-left: 45px;
}

.wrapper {
    width: 100%;
    *zoom: 1;
}

.wrapper:before,
.wrapper:after {
    display: table;
    content: '';
    line-height: 0;
}

.wrapper:after {
    clear: both;
}

.sidebar,
.main {
    display: block;
    width: 100%;
    min-height: 30px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    margin-left: 0;
}

.sidebar {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 45px;
    width: 25%;
}

.main {
    margin-left: 25%;
    width: 58.33333%;
}

.wrapper .main {
    padding-left: 12px;
}

.main-container {
    padding-left: 36px;
    margin: 35px 0 0;
}

.post-toc {
    position: fixed;
    right: 20px;
    max-width: 300px;
    overflow: auto;
    top: 100px;
    bottom: 50px;
}

.toc-wrapper {
    ::-webkit-scrollbar {
        width: 6px;
        height: 8px;
    }
    ::-webkit-scrollbar-thumb {
        height: 40px;
        background-color: #eee;
        border-radius: 16px;
        &:hover {
            background-color: #ddd;
        }
    }
}


// #region single page

.single .sidebar {
    display: none;
}

.single .main {
    margin-left: 16.66667%;
}

.single footer {
    margin-left: 16.66667%;
}
// #endregion

// #region navbar 

.navbar-menu {
    position: fixed;
    width: 80px;
    z-index: 1049;
    -webkit-backface-visibility: hidden;
    left: 0;
    top: 0;
    height: 100%;
    background: #2a2a2a;
}

.navbar-menu a {
    display: block;
    padding: 10px;
    font-size: 16px;
    line-height: 25px;
    color: #999999;
    text-align: center;
    text-shadow: 0 1px 0 black;
}

.navbar-menu a.logo {
    font-size: 18px;
    color: #fff;
}

.navbar-menu a.logo:hover {
    text-decoration: none;
}

.navbar-menu a.logo b {
    font-weight: normal;
}

.navbar-menu i {
    width: 16px;
    text-align: center;
}

.navbar-menu a:hover,
.navbar-menu a:active,
.navbar-menu a.active {
    background-color: #e78170;
    color: white;
    text-shadow: 0 1px 0 #de533a;
}

.navbar-menu .dropdown a:hover {
    background-color: #f49484;
}

.navbar-menu .dropdown a:active {
    background-color: #b34129;
}

.navbar-menu span,
.navbar-menu a.logo i {
    display: none;
}

.navbar-user .login {
    position: relative;
    top: 7px;
    float: right;
    padding: 0 2px;
    margin-right: 8px;
}

.navbar-user a {
    color: #555;
}

.navbar-user > .dropdown-menu {
    right: 2px;
    left: auto;
}

.navbar-user > .dropdown-menu::before,
.navbar-user > .dropdown-menu::after {
    left: 115px;
}

.navbar-user .dropdown-submenu .dropdown-menu {
    left: auto;
    right: 100%;
    margin-left: 0;
    margin-right: -1px;
    border-radius: 6px 0 6px 6px;
}

.navbar-user .dropdown-menu i {
    width: 18px;
    height: 15px;
    display: inline-block;
    text-align: center;
}

.navbar-user {
    position: fixed;
    top: 10px;
    right: 0;
    margin-bottom: 0;
    z-index: 1010;
}

.navbar-user .set-view-mode {
    position: relative;
    top: 7px;
    margin-right: 15px;
}
// #endregion

// #region night-mode

body.night-mode {
    background-color: #3f3f3f;
    color: #999;
    font-weight: lighter;
}

body.night-mode a {
    color: #b1b1b1;
}

body.night-mode a:hover {
    color: #fff;
}

body.night-mode .post-content a {
    color: #10a64f;
}

.night-mode .navbar-user,
.night-mode .page-title {
    background-color: #3f3f3f;
}

.night-mode .navbar-menu.shrink a {
    background-color: #222222;
    text-shadow: 0 1px 0 black;
    color: #b1b1b1;
}

.night-mode .navbar-menu.shrink a:hover {
    background-color: #555555;
    text-shadow: 0 1px 0 black;
}

.night-mode .navbar-menu a.dropdown-toggle:active {
    box-shadow: inset 0 0 10px black;
}

.night-mode .navbar-menu .dropdown-menu a:hover {
    background-color: #555555;
}

.night-mode input,
.night-mode #comment-form textarea,
.night-mode .tag-list a {
    background-color: #555;
    color: #999;
}

.night-mode .page-title > ul .active {
    border-color: #555;
}

body.night-mode .page-title .active a,
body.night-mode .page-title .active a:hover {
    color: #eee;
}

body.night-mode .page-title > ul a:hover {
    background-color: #333;
    color: #eee;
}

.night-mode .footer-inner,
.night-mode blockquote,
.night-mode input,
.night-mode .page-title,
.night-mode .main-container .post {
    border-color: #2f2f2f;
}

.night-mode .widget {
    border-color: #2f2f2f;
    -webkit-box-shadow: 0 1px 2px #2f2f2f;
    -moz-box-shadow: 0 1px 2px #2f2f2f;
    box-shadow: 0 1px 2px #2f2f2f;
}

.night-mode .widget .widget-desc,
.night-mode .widget .widget-title {
    border-color: #2f2f2f;
}

.night-mode .comment-list li,
.night-mode .comment-list .respond,
.night-mode #comment-form textarea {
    border-color: #2f2f2f;
}

.night-mode .comment-list li {
    background-color: #3f3f3f;
}

.night-mode .comment-list li.comment-by-author {
    border-left: 4px solid #10a64f;
    background-color: #555;
}

.night-mode .search-form input:focus,
.night-mode #comment-form textarea:focus {
    border-color: #10a64f;
}

.night-mode .fixed-btn,
.night-mode .fixed-btn a {
    background-color: #3f3f3f;
    border-color: #2f2f2f;
}

.night-mode .page-navigator li a {
    background-color: #555;
}

.night-mode .page-navigator li.current a {
    background-color: #10a64f;
}

// #endregion

// #region qrcode

.post-qrcode {
    position: relative;
    cursor: pointer;
}

.post-qrcode > div {
    position: absolute;
    display: none;
    width: 96px;
    height: 96px;
    padding: 10px;
    background-color: #fff;
    border: 1px dashed #d9d9d9;
}

.post-qrcode:hover > div {
    display: block;
}
// #endregion

// #region donate
.post-donate {
    position: relative;
    padding: 10px 20px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border-left: 4px solid #f57e42;
    box-sizing: border-box;
    box-shadow: 0 0 1px #d9d9d9;
    line-height: 28px;
}

.post-donate p {
    margin: 0;
    font-weight: bold;
}

.post-donate .post-donate-img {
    position: absolute;
    left: 0;
    width: 200px;
    background-color: #fff;
    border: 1px dashed #d9d9d9;
    display: none;
}

.post-donate-img p {
    margin: 0;
    padding: 0 10px;
    font-size: 14px;
    font-weight: bold;
    line-height: 32px;
    border-bottom: 1px dashed #d9d9d9;
}

.post-donate:hover .post-donate-img {
    display: block;
}

.donate-content ul {
    list-style: none;
    padding: 0;
}

.donate-tab li {
    float: left;
    padding: 8px 12px 8px 12px;
    border: 1px solid rgba(0, 0, 0, 0);
    border-bottom-color: #ddd;
    color: #0593d3;
    text-decoration: none;
    border-radius: 4px 4px 0 0;
    text-shadow: 0 1px 0 #fff;
    cursor: pointer;
}

.donate-tab li.active {
    border-color: #ddd;
    border-bottom-color: rgba(0, 0, 0, 0);
    background: #fff;
    color: #444;
    margin-bottom: -1px;
}

.donate-tab-con li {
    display: none;
}

.donate-tab-con li.active {
    display: block;
}

// #endregion

.post-footer {
    position: relative;
    margin: 100px 0 0 0;
    padding: 48px 0 0 0;
    border-top: #ccc 1px solid;
    text-align: center;
}

.post-footer p {
    margin: 10px 0 0;
}

.author-ava {
    height: 80px;
    width: 80px;
    overflow: hidden;
    border: 0px solid #666;
    border-radius: 100%;
    background-color: #fff;
    margin: -90px auto 20px;
}

.author-ava img {
    width: 100% !important;
    height: 100% !important;
}

code.has-jax {
    font: inherit;
    font-size: 100%;
    background: inherit;
    border: inherit;
    color: #515151;
}

// #region dropdown-menu
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
    background-color: white;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.2);
    *border-right-width: 2px;
    *border-bottom-width: 2px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
}

.dropdown-menu > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 20px;
    color: #3f3f3f;
    white-space: nowrap;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-submenu:hover > a,
.dropdown-submenu:focus > a {
    text-decoration: none;
    color: white;
    background-color: #4f4f4f;
    background-image: -moz-linear-gradient(top, #555, #484848);
    background-image: -webkit-gradient(
        linear,
        0 0,
        0 100%,
        from(#555),
        to(#484848)
    );
    background-image: -webkit-linear-gradient(top, #555, #484848);
    background-image: -o-linear-gradient(top, #555, #484848);
    background-image: linear-gradient(to bottom, #555, #484848);
    background-repeat: repeat-x;
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
    color: white;
    text-decoration: none;
    outline: 0;
    background-color: #4f4f4f;
    background-image: -moz-linear-gradient(top, #555, #484848);
    background-image: -webkit-gradient(
        linear,
        0 0,
        0 100%,
        from(#555),
        to(#484848)
    );
    background-image: -webkit-linear-gradient(top, #555, #484848);
    background-image: -o-linear-gradient(top, #555, #484848);
    background-image: linear-gradient(to bottom, #555, #484848);
    background-repeat: repeat-x;
   }

.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
    color: #999999;
}

.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
    text-decoration: none;
    background-color: transparent;
    background-image: none;
    cursor: default;
}

.open {
    *z-index: 1000;
}

.open > .dropdown-menu {
    display: block;
}
// #endregion

// #region sidebar

.cover-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
}

.sidebar .bottom-block {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 30px 0;
    color: white;
    text-align: center;
}

.sidebar .bottom-block h1,
.sidebar .bottom-block h2,
.sidebar .bottom-block h3,
.sidebar .bottom-block h4 {
    text-shadow: -1px 0 0 rgba(0, 0, 0, 0.75);
}

.sidebar a,
.sidebar a:hover {
    color: white;
}

.sidebar .img-info {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 0;
    text-align: right;
    color: white;
}

.sidebar .fa-info {
    position: relative;
    width: 18px;
    height: 18px;
    display: inline-block;
    margin: 0 10px 10px 0;
    background: black;
    border-radius: 50%;
    color: #d5d5d5;
    font-size: 12px;
    line-height: 19px;
    text-align: center;
    z-index: 9;
}

.sidebar .img-info .info {
    position: absolute;
    bottom: 10px;
    right: 15px;
    width: 0;
    padding: 4px 0 4px;
    overflow: hidden;
    background: black;
    border-radius: 10px;
    line-height: 10px;
    text-align: center;
    font-size: 10px;
    font-style: italic;
    color: #b1b1b1;
    white-space: nowrap;
}

.sidebar .img-info .info a {
    color: #d5d5d5;
    font-style: normal;
}

.sidebar .img-info .info a:hover {
    color: white;
}

.sidebar .img-info:hover .info {
    width: auto;
    padding: 4px 14px 4px 12px;
    -webkit-transition: all 0.2s cubic-bezier(0, 0.34, 0.71, 1.26) !important;
    -moz-transition: all 0.2s cubic-bezier(0, 0.34, 0.71, 1.26) !important;
    -ms-transition: all 0.2s cubic-bezier(0, 0.34, 0.71, 1.26) !important;
    -o-transition: all 0.2s cubic-bezier(0, 0.34, 0.71, 1.26) !important;
    transition: all 0.2s cubic-bezier(0, 0.34, 0.71, 1.26) !important;
}

// #endregion

// #region page-title
.page-title {
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 2px solid #d9d9d9;
    font-size: 12px;
    font-family: 'lucida grande', 'lucida sans unicode', lucida, helvetica,
        'Hiragino Sans GB', 'Microsoft YaHei', 'WenQuanYi Micro Hei', sans-serif;
}

.page-title .navigation {
    margin: 0;
    padding: 0;
    border-bottom: none;
    list-style: none;
    text-align: center;
}

.page-title li {
    float: left;
}

.page-title > ul a {
    padding: 15px;
    display: inline-block;
    color: #999999;
}

.page-title > ul a:hover {
    background-color: #eeeeee;
    color: #555555;
}

.page-title > ul .active {
    margin-bottom: -2px;
    border-bottom: 2px solid #555555;
}

.page-title > ul .active a {
    color: #555555;
}

.page-title .search {
    float: right;
    margin-top: 10px;
    min-width: 200px;
}

.page-title .search-form {
    margin: 0;
}
// #endregion

// #region search-form
.search-form {
    position: relative;
    width: 100%;
}

.search-form input {
    padding: 2px 30px 2px 10px;
    line-height: 22px;
    height: 28px;
}

.search-form input:focus {
    border: 1px solid #10a64f;
    outline: none;
}

.search-form button {
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 0;
    width: 32px;
    height: 28px;
    direction: ltr;
    -webkit-border-radius: 0 3px 3px 0;
    -moz-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0;
}
// #endregion

/* main-container */

.main-container .post {
    position: relative;
    width: 100%;
    padding-right: 2px;
    padding-bottom: 17px;
    margin: 0 0 17px;
    border-bottom: 1px dashed #d9d9d9;
    box-sizing: border-box;
    word-wrap: break-word;
}

.main-container:before,
.main-container:after {
    display: table;
    content: '';
    line-height: 0;
}

.post-top {
    margin: 8px 0;
    font-size: 12px;
}

.post-title {
    margin-top: 5px;
    margin-bottom: 10px;
    margin-left: 0;
    display: inherit;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.5;
}

.post-title a {
    color: #555;
}

.post-title a:hover {
    color: #000;
}

.preview .post-title {
    font-size: 32px;
}

.post-meta {
    font-family: 'lucida grande', 'lucida sans unicode', lucida, helvetica,
        'Hiragino Sans GB', 'Microsoft YaHei', 'WenQuanYi Micro Hei', sans-serif;
    font-size: 12px;
    font-weight: normal;
    line-height: 20px;
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.post-meta {
    color: #555;
}

.post-meta i {
    font-size: 14px;
}

.preview .post-meta {
    margin: 10px 0 0 0;
    overflow: visible;
}

.post-meta li {
    float: left;
    margin-right: 5px;
}

.post-content h2,
.comment-content h2 {
    font-size: 1.28571em;
}

.post-content img,
.comment-content img,
.post-content video,
.comment-content video {
    max-width: 100%;
}

.post-content {
    line-height: 1.5em;
    overflow: hidden;
}

.preview .post-content,
.preview .post-content p {
    line-height: 2em;
}

.post-content a {
    color: #10a64f;
}

.post-content a img,
.comment-content a img {
    background: #fff;
    position: relative;
    bottom: -4px;
}

.post-content hr,
.comment-content hr {
    margin: 2em auto;
    border: 1px dashed #e9e9e9;
    border-width: 2px 0 0 0;
}

.post .tags {
    clear: both;
}

.post-near {
    margin: 15px 0 0;
    padding: 0;
    color: #999;
    list-style: none;
    border-bottom: 1px dashed #d9d9d9;
}

.post-near li {
    margin: 10px 0;
}

.post-thumb {
    margin: 0 0 0 10px;
    float: right;
    width: 100px;
    height: 80px;
    overflow: hidden;
}

.post-thumb .thumb {
    display: block;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgba(38, 42, 53, 1);
    padding: 0;
    margin: 0;
}

.post-author {
    height: 32px;
    line-height: 32px;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px dashed #d9d9d9;
}

.post-author .fl {
    margin-right: 15px;
}

.post-tool {
    position: relative;
    width: 240px;
    margin: 20px auto;
}

.post-tool span a {
    display: inline-block;
    width: 115px;
    text-align: center;
    padding: 0;
    line-height: 36px;
}

.post-tool .post-donate {
    position: absolute;
    top: -8px;
    left: 94px;
    background-color: #fff;
}

.post-tool .post-donate a {
    width: 42px;
    font-size: 14px;
    height: 42px;
    line-height: 42px;
    border: 5px solid #fff;
}

// #region 评论
#comments {
    padding-top: 15px;
}

.comment-list,
.comment-list ol {
    margin: 0;
    padding: 0;
}

.comment-list li {
    padding: 10px;
    margin-top: 10px;
    border: 1px solid #eee;
    border-left: 4px solid #ddd;
    position: relative;
    list-style: none;
}

.comment-list li.comment-level-odd {
    background: #f6f6f3;
}

.comment-list li.comment-level-even {
    background: #fff;
}

.comment-list li.comment-by-author {
    border-left: 4px solid #10a64f;
}

.comment-list li.comment-by-author .comment-author {
    padding-right: 36px;
}

.comment-list li .comment-meta-reply {
    text-align: right;
    display: none;
}

li.comment-body:hover > .comment-meta > .comment-meta-reply {
    display: inline-block;
}

.comment-meta .comment-meta-author,
.comment-meta .comment-meta-time,
.comment-meta .comment-meta-reply {
    float: left;
    margin-right: 5px;
    line-height: 32px;
}

.comment-meta {
    overflow: hidden;
    height: 32px;
    line-height: 32px;
    margin-bottom: 5px;
}

.comment-meta a {
    color: #999;
    font-size: 0.92857em;
}

.comment-meta-author {
    display: block;
    line-height: 24px;
    color: #444;
}

.comment-meta-author .avatar {
    float: left;
    margin-right: 10px;
    width: 32px;
    height: 32px;
}

.comment-content {
    line-height: 24px;
    word-break: break-word;
}

.comment-content p {
    margin: 0;
}

.comment-meta-author cite {
    color: #999;
    font-size: 0.92857em;
    font-weight: bold;
    font-style: normal;
}

.comment-list .respond {
    margin-top: 15px;
    border-top: 1px solid #eee;
}

.respond .cancel-comment-reply {
    float: right;
    margin-top: 15px;
    font-size: 0.92857em;
}

#response {
    margin: 10px 0;
}

#comment-form {
    overflow: hidden;
}

#comment-form .comments-fields {
    position: relative;
}

#comment-form .field {
    width: 100%;
}

#comment-form .field label {
    display: inline-block;
    margin-bottom: 0.5em;
    font-weight: bold;
    width: 60px;
    text-align: right;
}

#comment-form .field input {
    display: inline-block;
    width: 50%;
}

#comment-form textarea,
#comment-form input {
    background-color: #fff;
}

#comment-form .submit {
    position: absolute;
    top: 0;
    right: 0;
}

#comment-form .required:before {
    content: ' *';
    color: #c00;
}

#comment-form input:focus,
#comment-form textarea:focus {
    border: 1px solid #10a64f;
}

#comments .page-navigator li {
    float: none;
    display: inline-block;
}

// #endregion

// #region tag-list 

ul.tag-list {
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.tag-list li {
    float: left;
    margin: 0 10px 10px 0;
    list-style: none;
}

.tag-list li a {
    display: inline-block;
    padding: 3px 10px;
    color: #666;
    background-color: #eee;
}

.tag-list li a:hover {
    background-color: #10a64f;
    color: #fff;
}
// 3endregion

/* archives */

.archives-item ul {
    margin: 0;
    padding-left: 65px;
    line-height: 2em;
}

/* widget */

.widget {
    border: 1px solid #d4d4d4;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 0 1px 2px #ddd;
    -moz-box-shadow: 0 1px 2px #ddd;
    box-shadow: 0 1px 2px #ddd;
    margin-bottom: 15px;
}

.widget .widget-title {
    margin: 0;
    line-height: 36px;
    font-size: 16px;
    font-weight: bold;
    padding-left: 15px;
}

.widget .widget-desc,
.widget .widget-title {
    padding: 5px 10px;
    border-bottom: 1px solid #ddd;
}

.widget .widget-title span {
    float: right;
    font-size: 12px;
    margin-right: 10px;
}

.widget-list {
    padding: 5px 10px;
    margin: 0;
}

.widget-list li {
    margin: 5px 0;
    line-height: 1.5;
    list-style: none;
}

.widget-list li ul {
    margin-left: 15px;
}

.categories .widget {
    margin: 0 10px 20px;
}

/* 分页 */

.page-navigator {
    margin: 25px 0;
    padding: 0;
    text-align: center;
    overflow: hidden;
}

.page-navigator a {
    display: inline-block;
    padding: 0 20px;
    height: 32px;
    line-height: 32px;
    background-color: #10a64f;
    color: #fff;
}

.page-navigator a:hover {
    background: #0c843f;
    text-decoration: none;
}

.page-navigator li {
    float: left;
    list-style: none;
}

.page-navigator li a {
    background-color: #f6f6f3;
    color: #404040;
}

.page-navigator li a:hover {
    color: #fff;
}

.page-navigator li.current a {
    background-color: #0c843f;
    color: #fff;
}

// #region footer 

footer {
    margin: 8px 0 10px 25%;
    overflow: hidden;
    color: #999999;
    box-sizing: border-box;
    font-family: 'lucida grande', 'lucida sans unicode', lucida, helvetica,
        'Hiragino Sans GB', 'Microsoft YaHei', 'WenQuanYi Micro Hei', sans-serif;
    font-size: 12px;
    font-weight: normal;
    line-height: 20px;
}

footer a {
    color: #999999;
}

.footer-inner {
    border-top: 1px solid #d9d9d9;
    padding-left: 20px;
    padding-top: 5px;
    margin-left: 11px;
}

.footer-inner p {
    margin: 0 0 0 15px;
}

.footer-inner img {
    width: 60px;
    margin-left: 5px;
}
// #endregion

.app-download-btn {
    display: inline-block;
    margin-right: 3px;
}

.app-download-btn a img {
    width: 90px;
}


 

// #region fixed-btn * 

.fixed-btn {
    position: fixed;
    right: 40px;
    bottom: 40px;
    width: 42px;
    background-color: #fff;
    z-index: 1040;
    -webkit-backface-visibility: hidden;
}

.fixed-btn a {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border: 1px solid #e9e9e9;
    color: #555;
    border-bottom: none;
}

.fixed-btn a:hover {
    color: #000;
}

.fixed-btn a:last-child {
    border-bottom: 1px solid #e9e9e9;
}
// #endregion

// #region qrcode
.page-qrcode {
    position: relative;
    cursor: pointer;
}

.page-qrcode > div {
    position: absolute;
    right: 56px;
    top: -48px;
    display: none;
    width: 96px;
    height: 128px;
    padding: 10px;
    background-color: #fff;
    border-radius: 6px;
    -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding-box;
    background-clip: padding-box;
}

.page-qrcode:hover > div {
    display: block;
}

.page-qrcode p {
    padding: 0;
    margin: 0;
    height: 32px;
    line-height: 32px;
}
// #endregion

.dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    padding: 10px;
    background-color: #fff;
    display: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    z-index: 2048;
}

.dialog.error {
    background-color: #f40;
    color: #fff;
}

.dialog.success {
    background-color: #24aa42;
    color: #fff;
}

#overlay {
    position: fixed;
    z-index: 2047;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: none;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

/* layout */

.avatar {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: all 0.49s;
    -moz-transition: all 0.49s;
    -ms-transition: all 0.49s;
    -o-transition: all 0.49s;
    transition: all 0.49s;
    max-width: 100px;
}

.avatar:hover {
    transform: rotateZ(360deg);
}

.fa-homepage:before {
    content: '\f015';
}

.fa-links:before {
    content: '\f0c1';
}

.fa-contact:before,
.fa-guestbook:before {
    content: '\f086';
}

.fa-about:before {
    content: '\f05a';
}

.fa-archives:before {
    content: '\f187';
}

.fa-categories:before {
    content: '\f02d';
}

.fa-services:before {
    content: '\f0f2';
}

@media (max-width: 480px) {
    .nav {
        margin-right: 0;
    }

    .search,
    .search-query {
        display: none;
    }
}

@media (max-width: 768px) {
    body {
        padding-left: 0;
    }

    .main {
        width: 100%;
        padding: 50px 10px 0;
        margin-left: 0 !important;
    }

    .main .page-title,
    .main-container {
        padding: 0;
    }

    .sidebar {
        display: none !important;
    }

    .navbar-user {
        top: 0;
        left: 0;
        height: 45px;
        background: white;
        box-shadow: 0 1px 4px #eeeeee;
    }

    .navbar-user .user.avatar {
        top: 3px;
    }

    .navbar-user .login,
    .wrap-btn .login {
        top: 13px;
    }

    .navbar-menu {
        background: none;
        width: 100%;
        height: 50px;
    }

    .navbar-menu a {
        background-color: none;
        text-shadow: none;
        display: inline-block;
        font-size: 14px;
    }

    .navbar-user .set-view-mode {
        top: 13px;
        display: inline-block;
    }

    .post-toc {
        display: none;
    }

    #comment-form .span4 {
        width: 100%;
    }

    .night-mode .navbar-user {
        box-shadow: 0 1px 4px #2f2f2f;
    }

    .night-mode .navbar-menu a {
        background-color: #222;
        text-shadow: 0 1px 0 #000;
    }

    footer {
        margin: 0 10px !important;
    }

    .footer-inner {
        padding-left: 0;
        margin-left: 0;
    }
}

@media (min-width: 1588px) {
    body {
        padding-left: 170px;
    }

    .sidebar {
        left: 170px;
    }

    .wrapper .main {
        padding-left: 45px;
    }

    
    .navbar-menu {
        width: 170px;
    }

    .navbar-menu a {
        padding: 10px 15px;
        text-align: left;
    }

    .navbar-menu .logo b {
        display: none;
    }

    .navbar-menu .logo i {
        display: inline-block !important;
        margin: 0;
    }

    .navbar-menu span.title {
        display: inline-block;
        margin-left: 8px;
        font-size: 14px;
        font-weight: normal;
        line-height: 14px;
    }

    .navbar-menu .title {
        padding: 0;
    }

    
    .footer-inner {
        margin-left: 43px;
    }
}